import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdUpload, MdClose } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { backendRoot, signedUrlForUploadPath } from "backendInfo";
import { Progress } from "@chakra-ui/react";

function UploadFile({ onFileUpload, brandColor, textColorSecondary }) {
  const [file, setFile] = useState(null);
  const [audioDuration, setAudioDuration] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadMessage, setUploadMessage] = useState(null);

  const loadAudioDuration = (file) => {
    return new Promise((resolve) => {
      const audio = new Audio(URL.createObjectURL(file));
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
    });
  };

  const handleUpload = async (acceptedFiles) => {
    const allowedTypes = [
      "audio/mpeg",
      "audio/wav",
      "audio/ogg",
      "video/quicktime",
      "video/mp4",
    ];
    const file = acceptedFiles[0];

    if (!allowedTypes.includes(file.type)) {
      alert(
        "Invalid file type. Only MP3, WAV, OGG, MOV, and MP4 files are allowed."
      );
      return;
    }

    setFile(file);
    setUploading(true);
    setUploadMessage("Requesting signed URL...");

    try {
      const audioDuration = await loadAudioDuration(file);
      setAudioDuration(audioDuration);
      const response = await axios.post(
        `${backendRoot}${signedUrlForUploadPath}`,
        { file_name: file.name }
      );
      const { signed_url, object_name } = response.data;

      setUploadMessage("Uploading file...");

      await axios.put(signed_url, file, {
        headers: { "Content-Type": "application/octet-stream" },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
      });

      setUploadMessage("File uploaded successfully!");
      onFileUpload(file.name, object_name, audioDuration);
      console.log("On file upload success called");
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadMessage("File upload failed. Please try again.");
    }

    setUploading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    maxSize: 10 * 1024 * 1024 * 1024, // 10 GB
  });

  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  return (
    <>
      {!file ? (
        <Flex
          align="center"
          justify="center"
          bg={bg}
          border="1px dashed"
          borderColor={borderColor}
          borderRadius="16px"
          w="100%"
          maxW="100%"
          h="max-content"
          maxH={{ sm: "100%", lg: "100%", "2xl": "100%" }}
          minH="200px"
          cursor="pointer"
          {...getRootProps()}
        >
          <Input maxW="100%" variant="main" {...getInputProps()} />
          <Button variant="no-effects" w="100%" maxW="100%">
            <Icon as={MdUpload} w="40px" h="40px" color={brandColor} />
            <Text fontSize="xl" fontWeight="700" color={brandColor}>
              Upload Files
            </Text>
          </Button>
        </Flex>
      ) : (
        <Flex
          direction="column"
          bg={bg}
          border="1px solid"
          borderColor={borderColor}
          borderRadius="16px"
          w="100%"
          h="max-content"
          minW="100%"
          minH="60px"
          p="20px"
        >
          <Flex justify="space-between" align="center" w="100%">
            <Text fontSize="md" fontWeight="500" color={textColorSecondary}>
              {file.name}
            </Text>
            <Icon
              as={MdClose}
              w="20px"
              h="20px"
              color={textColorSecondary}
              cursor="pointer"
              onClick={() => {
                setFile(null);
                setUploading(false);
                setUploadMessage(null);
                onFileUpload(null, null);
              }}
            />
          </Flex>

          {uploading && (
          <Flex direction="column" align="flex-start" mt="20px">
          <Text
            fontSize="md"
            fontWeight="500"
            color={textColorSecondary}
            mb="10px"
          >
            {uploadMessage}
          </Text>
          <Progress hasStripe value={uploadProgress} />
        </Flex>
          )}
        </Flex>
      )}
    </>
  );
}

export default UploadFile;
