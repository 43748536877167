import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdLock,
  MdAddTask , 
} from "react-icons/md";

import {
  BsListTask
} from "react-icons/bs"; 


// Task imports
import NewTask from "views/tasks/new";
import ListTasks from "views/tasks/list";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "/default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: MainDashboard,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  {
    name: "New Task",
    layout: "/tasks",
    path: "/new",
    icon: <Icon as={MdAddTask} width='20px' height='20px' color='inherit' />,
    component: NewTask,
  },
  {
    name: "My Tasks",
    layout: "/tasks",
    path: "/list",
    icon: <Icon as={BsListTask} width='20px' height='20px' color='inherit' />,
    component: ListTasks,
  },
  {
    name: "Payment Success",
    layout: "/payments",
    path: "/success",
    icon: <Icon as={BsListTask} width='20px' height='20px' color='inherit' />,
    component: ListTasks,
  },
  {
    name: "My Tasks",
    layout: "/payments",
    path: "/failed",
    icon: <Icon as={BsListTask} width='20px' height='20px' color='inherit' />,
    component: ListTasks,
  },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },

];

export default routes;
