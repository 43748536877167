import { useEffect, useRef } from 'react';
import {
  Flex,
  Image,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/logoWhite.png";
import React from "react";
import BuyMoreCreditsButton from "./BuyMore";
import manager from 'helpers/manager';
import { useApp } from 'contexts/AppProvider';
import Spinner from 'components/spinner/spinner'; 
export default function SidebarDocs() {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const borderColor = useColorModeValue("white", "navy.800");
  const ceo = useApp()

  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const credits = await manager.fetchAvailableCredits();
        ceo.actions.setAvailableCredits(credits);
      } catch (error) {
        console.error('Error fetching available credits:', error);
      }
    };

    fetchCredits(); // Fetch credits initially

    if (intervalRef.current === null) {
      intervalRef.current = setInterval(fetchCredits, 10000); // Fetch credits every 10 seconds
    }

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current); // Clean up the interval on component unmount
        intervalRef.current = null;
      }
    };
  }, []);


  return (
    <Flex
      justify='center'
      direction='column'
      align='center'
      bg={bgColor}
      borderRadius='30px'
      position='relative'>
      <Flex
        border='5px solid'
        borderColor={borderColor}
        bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
        borderRadius='50%'
        w='94px'
        h='94px'
        align='center'
        justify='center'
        mx='auto'
        position='absolute'
        left='50%'
        top='-47px'
        transform='translate(-50%, 0%)'>
        <Image src={logoWhite} w='40px' h='40px' />
      </Flex>
      <Flex
        direction='column'
        mb='12px'
        align='center'
        justify='center'
        px='15px'
        pt='55px'>
        <Text
          fontSize={{ base: "lg", xl: "18px" }}
          color='white'
          fontWeight='bold'
          lineHeight='150%'
          textAlign='center'
          px='10px'
          mt="10px"
          mb='6px'>
          {ceo.states.availableCredits !== null ? (
            `Available Credits: £${ceo.states.availableCredits}`
          ) : (
            <Spinner size='sm' color='white' thickness='2px' />
          )}
        </Text>
      </Flex>
      <BuyMoreCreditsButton />
    </Flex>
  );
}
