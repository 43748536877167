import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import BackdropModal from "components/modal/BackdropModal";
import manager from "helpers/manager";
import { STRIPE_PUBLISHABLE_KEY } from "helpers/constants";
import Spinner from "components/spinner/spinner";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function CheckoutForm() {
  const stripe = useStripe();
  const [amount, setAmount] = useState("");
  const [proceed,setProceed]=useState(false)
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleProceed = async () => {
    // Make an API call with the form data using the addMoreCredits function
    try {
      setProceed(true);
      const response = await manager.addMoreCredits({'amount' :  amount });
      const data = await response.json();
      console.log(data);

      // Create a Stripe Checkout session
      const result = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
        customerEmail: data.customerEmail,
      });

      if (result.error) {
        setProceed(false);
        // Handle any errors
        console.error("Error:", result.error);
      }
    } catch (error) {
      setProceed(false);

      // Handle any errors
      console.error("Error:", error);
    }
    finally{
      setProceed(false)
    }
  };

  return (
    <FormControl>
      <FormLabel>Enter Amount you want to add (£)</FormLabel>
      <Input
        placeholder="Amount in (£)"
        value={amount}
        onChange={handleAmountChange}
      />
      {proceed? <Spinner  size = 'lg' mt={4}/>:
      <Button mt={4} colorScheme="blue" onClick={handleProceed}>
        Proceed
      </Button>}
    </FormControl>
  );
}

function BuyMoreCreditsButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        bg="whiteAlpha.300"
        _hover={{ bg: "whiteAlpha.200" }}
        _active={{ bg: "whiteAlpha.100" }}
        mb={{ sm: "16px", xl: "24px" }}
        color={"white"}
        fontWeight="regular"
        fontSize="sm"
        minW="185px"
        mx="auto"
        onClick={onOpen}
      >
        Buy more credits
      </Button>

      <BackdropModal
        title="Add More Credits"
        modalSize="2xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </BackdropModal>
    </>
  );
}

export default BuyMoreCreditsButton;