import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";

const ModalOverlayOne = () => (
  <ModalOverlay
    bg='none'
    backdropFilter='auto'
    backdropInvert='0%'
    backdropBlur='3px'
  />
);

export default function BackdropModal(props) {
  const { title, modalSize, isOpen, onClose, ...rest } = props;

  return (
    <>
    <Modal onClose={onClose} size={modalSize} isOpen={isOpen} isCentered>
        <ModalOverlayOne />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Card
              align="center"
              direction="column"
              w="100%"
              // maxW="max-content"
              p="10px 15px"
              h="max-content"
              {...rest}
            >
              {props.children}
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}