import React, { useLayoutEffect, useEffect, useState} from 'react';
import {  Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { backendRoot, verifyTokenPath } from '../backendInfo';
import { useApp } from '../contexts/AppProvider';
import Spinner from 'components/spinner/spinner';
const Auth = (props) => {
  const ceo = useApp();
  const [loading, setLoading] = useState(true);

  console.log("In Auth start")
  const verifyToken = () => {
    const token = Cookies.get('token');
    if (token) {
      const url = `${backendRoot}${verifyTokenPath}`;
      axios
        .post(url, { token })
        .then((res) => {
          if (res.status === 200) {
            console.log("In Authenticated 1")
            ceo.actions.setIsAuthenticated(1);
          }
        })
        .catch((err) => {
          console.log('Error:', err);
          ceo.actions.setIsAuthenticated(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      ceo.actions.setIsAuthenticated(0);
      setLoading(false);
    }
  };

  console.log(props, ceo.states.isAuthenticated )

  useEffect(() => {
    verifyToken();
  }, []);

  if (loading) {
    return <Spinner  />;
  }

  return ceo.states.isAuthenticated === 1 ? (
    <> {props.children} </>
  ) : (
    <Redirect to="/auth" />
  );

};

export default Auth;