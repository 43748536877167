
import React from "react";
// import TranscribeForm from 'components/TranscribeForm';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import TaskLayout from 'layouts/tasks';
import Auth from 'hooks/Auth';
import PaymentSuccess from "views/payments/success";
import PaymentCancel from "views/payments/cancel";

function App() {
  console.log("In App")
  return (
    <Router>
      <Switch>
        <Route path={`/auth`} component={AuthLayout} />
        <Route path={`/admin`} element={<Auth> <AdminLayout />  </Auth> } />
        <Route path={`/tasks`}>
          <Auth> 
            <TaskLayout />  
          </Auth>
        </Route>
        <Route path={`/payments/success`}>
          <Auth> 
            <PaymentSuccess />  
          </Auth>
        </Route>
        <Route path={`/payments/failed`}>
          <Auth> 
            <PaymentCancel />  
          </Auth>
        </Route>
        <Redirect from='/' to='/tasks' />
      </Switch>
    </Router>


  );
}

export default App;
