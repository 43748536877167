import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/index.css';
import 'assets/css/App.css';
import App from 'App';
import theme from 'theme/theme';

// providers
import { AppProvider } from "contexts/AppProvider";
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <AppProvider>
          <App />
        </AppProvider>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>
);