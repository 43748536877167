import React from 'react';
import { Spinner as ChakraSpinner, Box } from '@chakra-ui/react';

const Spinner = (props) => {
  const {
    thickness = '4px',
    speed = '0.65s',
    emptyColor = 'gray.200',
    color = 'purple.500',
    size = 'xl',
    ...rest
  } = props;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" >
      <ChakraSpinner
        thickness={thickness}
        speed={speed}
        emptyColor={emptyColor}
        color={color}
        size={size}
        {...rest}
      />
    </Box>
  );
};

export default Spinner;