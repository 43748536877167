// Chakra imports
import { Box, SimpleGrid, Alert, AlertIcon,Flex, } from "@chakra-ui/react";
import ComplexTable from "./components/dataTables/ComplexTable";
import Spinner from "components/spinner/spinner";
import { columnsDataComplex } from "./components/dataTables/columnsData";
import tableDataComplex from "./components/dataTables/tableDataComplex.json";
import React, { useState, useEffect } from "react";
import { backendRoot, taskPath } from "backendInfo";
import manager from "helpers/manager";

export default function ListTasks() {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Ensure initial state is true
  const [error, setError] = useState(null);
  const handleReload = () => {
    setIsLoading(true); // Set loading to true before fetching
    manager.getTasks()
      .then(response => response.json())
      .then(data => {
        // Assuming the API returns an array of tasks
        setTableData(data);
        setIsLoading(false); // Set loading to false after data is loaded
      })
      .catch(error => {
        console.error("Error reloading tasks:", error);
        setIsLoading(false); // Set loading to false if an error occurs
        // Optionally, you can add error handling here (e.g., show an error message to the user)
      });
  };
  const handleDownload = (uuid) => {
    console.log(uuid);
    // const downloadUrl = `${backendRoot}${taskPath}/${uuid}/download`; // Adjust the URL path as needed
    // fetch(downloadUrl, {
    //     method: 'GET', // or 'POST' if required by your backend
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    // })
    // .then(response => {
    //     if (!response.ok) {
    //         throw new Error('Network response was not ok');
    //     }
    //     return response.blob(); // Assuming the server sends a file to download
    // })
    // .then(blob => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = "downloaded_file"; // Provide a default filename or derive from response
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //     window.URL.revokeObjectURL(url);
    // })
    // .catch(error => {
    //     console.error('Download error:', error);
    //     alert('Download failed: ' + error.message);
    // });
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        setIsLoading(true); // Ensure loading state is set to true
        const response = await manager.getTasks();
        if (response.ok) {
          const data = await response.json();
          setTableData(data);
          setIsLoading(false);
        } else {
          throw new Error("Failed to fetch tasks");
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
        setError(error);
        setIsLoading(false); // Ensure loading state is reset on error
      }
    };

    fetchTasks();
  }, []);

  // if (isLoading) {
  //     return <Spinner color="blue.500" size="xl" />;
  // }

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        There was an error processing your request: {error.message}
      </Alert>
    );
  }

  return (
    <>
      {" "}
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {isLoading ? (
          <Flex justify="center" align="center" height="100vh">
            <Spinner color="blue.500" size="xl" />
          </Flex>
        ) : (
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 1, lg: 1 }}
            spacing={{ base: "20px", xl: "20px" }}
          >
            <ComplexTable
              columnsData={columnsDataComplex}
              tableData={tableData}
              handleDownload={handleDownload}
              handleReload={handleReload}
            />
          </SimpleGrid>
        )}
      </Box>
    </>
  );
}
