import React from "react";
import {
  Heading,
  Text,
  useColorModeValue,
  Icon,
  VStack,
  Center,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

function PaymentCancel() {
  const textColor = useColorModeValue("gray.700", "white");
  const textColorSecondary = "gray.600";

  return (
    <Center h="100vh">
      <VStack spacing={6} align="center" textAlign="center">
        <Icon as={CloseIcon} boxSize={16} color="red.500" />
        <Heading as="h1" size="2xl" color={textColor}>
          Payment Failed
        </Heading>
        <Text fontSize="xl" color={textColorSecondary} maxW="md">
          Your payment was not successful. Please try again or contact support
          if the issue persists.
        </Text>
        <Link to="/tasks">
          <Text fontSize="lg" color="blue.500" textDecoration="underline">
            Back to Home
          </Text>
        </Link>
      </VStack>
    </Center>
  );
}

export default PaymentCancel;