// Chakra imports
import {
  Box,
  Flex,
  FormLabel,
  Select,
  SimpleGrid,
  useColorModeValue,
  Button,
  Checkbox,
  FormControl,
  Input,
  InputGroup,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import Card from "components/card/Card.js";
import manager from "helpers/manager";
import Spinner from "components/spinner/spinner";
import { languages } from "helpers/constants";
import UploadFile from "views/tasks/new/components/UploadFile";
// Assets
import React, { useState, useEffect } from "react";
import { useApp } from "contexts/AppProvider";

export default function NewTask() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColorSecondary = useColorModeValue("grey.800", "white");

  // States
  const ceo = useApp();
  const toast = useToast();

  const [url, setUrl] = useState("");
  const [mediaType, setMediaType] = useState(1);
  const [numSpeakers, setNumSpeakers] = useState(1);
  const [toEmail, setToEmail] = useState(ceo.states.userEmail);
  const [language, setLanguage] = useState("en");
  const [startPoint, setStartPoint] = useState("*");
  const [endPoint, setEndPoint] = useState("*");
  const [startPointChecked, setStartPointChecked] = useState(true);
  const [endPointChecked, setEndPointChecked] = useState(true);
  const [gcsObjectName, setGcsObjectName] = useState(null);
  const [fileTitle, setFileTitle] = useState(null);
  const [audioDuration, setAudioDuration] = useState(null);
  const [model, setModel] = useState("whisper");

  const [transcribing, setTranscribing] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    setToEmail(ceo.states.userEmail);
  }, [ceo.states.userEmail]);

  const handleStartPointChange = (event) => {
    setStartPointChecked(event.target.checked);
    if (event.target.checked) {
      setStartPoint("*");
    }
  };

  const handleEndPointChange = (event) => {
    setEndPointChecked(event.target.checked);
    if (event.target.checked) {
      setEndPoint("*");
    }
  };

  const handleSubmit = async () => {
    setTranscribing(true);

    try {
      // Validation
      if (!isValidEmail(toEmail)) {
        toast({
          title: "Invalid Email",
          description: "Please enter a valid email address.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!startPoint || !endPoint) {
        toast({
          title: "Missing Fields",
          description: "Please fill in all required fields.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!url && !gcsObjectName) {
        toast({
          title: "Missing Fields",
          description: "Please upload file or add youtube url link",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const isValidTime = (time) =>
        /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/.test(time) || time === "*";

      if (!isValidTime(startPoint)) {
        toast({
          title: "Invalid Start Time",
          description: "Please use the format HH:MM:SS or *.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!isValidTime(endPoint)) {
        toast({
          title: "Invalid End Time",
          description: "Please use the format HH:MM:SS or *.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      let requestBody = {
        model,
        toEmail,
        mediaType,
        numSpeakers,
        language,
        startPoint,
        endPoint,
      };

      if (mediaType === 1) {
        requestBody.url = url;
      } else if (mediaType === 2) {
        requestBody.gcsFileName = gcsObjectName;
        requestBody.fileTitle = fileTitle;
        requestBody.audioDuration = audioDuration;
      }

      const response = await manager.submitRequest(requestBody);
      const data = await response.json();

      if (response.ok) {
        toast({
          title: "Success",
          description: data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        resetFields();
      } else {
        toast({
          title: "Request Failed",
          description: data.message || "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      toast({
        title: "Error",
        description: "An error occurred. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setTranscribing(false);
    }
  };

  const isValidEmail = (email) => {
    // Simple email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const resetFields = () => {
    setUrl("");
    setMediaType(1);
    setNumSpeakers(1);
    // setToEmail("");
    setLanguage("en");
    setStartPoint("*");
    setEndPoint("*");
    setStartPointChecked(true);
    setEndPointChecked(true);
    setGcsObjectName(null);
    setFileTitle(null);
  };

  const handleFileUpload = (actualFileName, gcsObjectName, audioDuration) => {
    setFileTitle(actualFileName);
    setGcsObjectName(gcsObjectName);
    setMediaType(2);
    setUrl("");
    setAudioDuration(audioDuration);
  };

  useEffect(() => {
    if (response) {
      toast({
        description: response,
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [response, toast]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Results Email <Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="email"
            mb="24px"
            fontWeight="500"
            size="lg"
            value={toEmail}
            color={textColorSecondary}
            onChange={(e) => setToEmail(e.target.value)}
          />
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Select Model<Text color={brandStars}></Text>
          </FormLabel>
          <RadioGroup
            mb="24px"
            fontWeight="500"
            size="lg"
            value={model}
            onChange={setModel}
          >
            <Stack direction="row">
              <Radio value="whisper">OpenAI Whisper</Radio>
              <Radio value="chirp">Google Chirp</Radio>
            </Stack>
          </RadioGroup>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Youtube URL<Text color={brandStars}></Text>
          </FormLabel>
          <Input
            isRequired={false}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="Enter URL"
            mb="24px"
            fontWeight="500"
            size="lg"
            value={url}
            color={textColorSecondary}
            onChange={(e) => {
              setUrl(e.target.value);
              setMediaType(e.target.value ? 1 : 0); // Set mediaType to 1 if URL is provided
            }}
            disabled={gcsObjectName !== null} // Disable if file is uploaded
          />
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>
          <Card mb="20px" align="center" p="10px">
            <Flex h="100%" direction={{ "2xl": "row" }}>
              <UploadFile
                onFileUpload={handleFileUpload}
                brandColor={brandColor}
                textColorSecondary={textColorSecondary}
              />
            </Flex>
          </Card>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="md"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Number of speakers : {numSpeakers}
            <Text color={brandStars}></Text>
          </FormLabel>
          <Slider
            isRequired
            fontSize="sm"
            ms={{ md: "0px" }}
            mb="24px"
            fontWeight="500"
            size="md"
            value={numSpeakers}
            onChange={(value) => setNumSpeakers(value)}
            max={10}
            min={1}
            disabled={model === "chirp"}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>

          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Select language<Text color={brandStars}></Text>
          </FormLabel>
          <Select
            value={language}
            label="Language"
            onChange={(e) => setLanguage(e.target.value)}
          >
            {Object.entries(languages).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Select>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Start Point<Text color={brandStars}></Text>
          </FormLabel>
          <InputGroup size="md" display="flex" mb="20px">
            <InputLeftElement>
              <Checkbox
                isChecked={startPointChecked}
                onChange={handleStartPointChange}
                name="startPoint"
                colorScheme="gray"
                size="lg"
              />
            </InputLeftElement>
            <Input
              isRequired={false}
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              fontWeight="500"
              size="lg"
              pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]"
              value={startPoint}
              onChange={(e) => setStartPoint(e.target.value)}
              margin="normal"
              placeholder="Format: HH:MM:SS or *"
              color={textColorSecondary}
              disabled={startPointChecked}
            />
          </InputGroup>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            End Point<Text color={brandStars}></Text>
          </FormLabel>
          <InputGroup size="md" display="flex" mb="20px">
            <InputLeftElement>
              <Checkbox
                isChecked={endPointChecked}
                onChange={handleEndPointChange}
                name="endPoint"
                colorScheme="gray"
                size="lg"
              />
            </InputLeftElement>
            <Input
              isRequired={false}
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              fontWeight="500"
              size="lg"
              pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]"
              value={endPoint}
              onChange={(e) => setEndPoint(e.target.value)}
              margin="normal"
              placeholder="Format: HH:MM:SS or *"
              color={textColorSecondary}
              disabled={endPointChecked}
            />
          </InputGroup>
          {transcribing ? (
            <Spinner />
          ) : (
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleSubmit}
            >
              Transcribe
            </Button>
          )}
        </FormControl>
      </SimpleGrid>
    </Box>
  );
}
