import Cookies from "js-cookie";
import { backendRoot } from "../backendInfo";

const manager = {
  // manager is basically api call manager and manages the api calls to backend

  ///USERS
  signIn: (email, password) => {
    const form = new FormData();
    form.append("email", email);
    form.append("password", password);
    // console.log(email, password);
    return fetch(`${backendRoot}/account/auth/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: form,
    });
  },
  // Task management
  createTask: (taskData) => {
    return fetch(`${backendRoot}/whisper/tasks/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      body: JSON.stringify(taskData),
    });
  },

  getTasks: () => {
    return fetch(`${backendRoot}/whisper/tasks/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
  },

  getTask: (taskId) => {
    return fetch(`${backendRoot}/whisper/tasks/${taskId}/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
  },

  updateTask: (taskId, taskData) => {
    return fetch(`${backendRoot}/whisper/tasks/${taskId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      body: JSON.stringify(taskData),
    });
  },

  deleteTask: (taskId) => {
    return fetch(`${backendRoot}/whisper/tasks/${taskId}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
  },

  getTaskQueue: () => {
    return fetch(`${backendRoot}/whisper/tasks/queue/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
  },

  // File upload
  // uploadFile: (file) => {
  //   console.log("file upload")
  //   const form = new FormData();
  //   form.append("file", file);
  //   return fetch(`${backendRoot}/whisper/upload/`, {
  //     method: "POST",
  //     body: form,
  //   });
  // },

  // uploadFile: (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     const bucketName="bucket_for_transcribe_process"
  //     reader.onload = (e) => {
  //       const fileData = e.target.result;
  //       const xhr = new XMLHttpRequest();
  //       const url = `https://storage.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=media&name=${encodeURIComponent(file.name)}`;
  
  //       xhr.open('POST', url, true);
  //       xhr.setRequestHeader('Authorization', `Bearer ${Cookies.get("token")}`);
  //       xhr.setRequestHeader('Content-Type', file.type);
  //       xhr.onload = () => {
  //         if (xhr.status === 200) {
  //           resolve(JSON.parse(xhr.responseText));
  //         } else {
  //           reject(new Error(`Upload failed: ${xhr.responseText}`));
  //         }
  //       };
  //       xhr.onerror = () => reject(new Error('Network error during upload'));
  //       xhr.send(fileData);
  //     };
  
  //     reader.onerror = (error) => reject(error);
  //     reader.readAsArrayBuffer(file);
  //   });
  // },


  uploadFile: (file,taskId) => {
    return new Promise((resolve, reject) => {
      // First, get the signed URL from the backend
      fetch(`${backendRoot}/whisper/get-signed-url/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get("token")}`
        },
        body: JSON.stringify({ filename: file.name, contentType: file.type ,task_id:taskId})
      })
      .then(response => response.json())
      .then(data => {
        const { signedUrl, fileUrl } = data;
        
        // Now upload the file using the signed URL
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', signedUrl, true);
        xhr.setRequestHeader('Content-Type', file.type);
        
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve({ fileUrl });
          } else {
            reject(new Error(`Upload failed: ${xhr.responseText}`));
          }
        };
        xhr.onerror = () => reject(new Error('Network error during upload'));
        
        xhr.send(file);
      })
      .catch(error => reject(error));
    });
  },
  
  // Submit request
  submitRequest: (requestData) => {
    return fetch(`${backendRoot}/whisper/submit-request/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      body: JSON.stringify(requestData),
    });
  },

  // Submit request
  addMoreCredits: (requestData) => {
    return fetch(`${backendRoot}/payments/create-checkout-session/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      body: JSON.stringify(requestData),
    });
  },
  fetchUserInfo: async () => {
    return fetch(`${backendRoot}/accounts/user-info/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch(error => {
      console.error('Error fetching user info:', error);
      throw error;
    });
  },

  
  fetchAvailableCredits: async () => {
    return fetch(`${backendRoot}/whisper/available-credits/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch available credits");
        }
        return response.json();
      })
      .then((data) => {
        return data.available_credits;
      });
  },

};

export default manager;