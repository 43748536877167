import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
  Icon,
  VStack,
  Center,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

function PaymentSuccess() {
  const textColor = useColorModeValue("gray.700", "white");
  const textColorSecondary = "gray.600";

  return (
    <Center h="100vh">
      <VStack spacing={6} align="center" textAlign="center">
        <Icon as={CheckCircleIcon} boxSize={16} color="green.500" />
        <Heading as="h1" size="2xl" color={textColor}>
          Payment Successful
        </Heading>
        <Text fontSize="xl" color={textColorSecondary} maxW="md">
          Your payment has been successfully processed. Credits will be reflected
          in your account shortly.
        </Text>
        <Link to="/tasks">
          <Text fontSize="lg" color="blue.500" textDecoration="underline">
            Back to Home
          </Text>
        </Link>
      </VStack>
    </Center>
  );
}

export default PaymentSuccess;