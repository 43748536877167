import React from "react";
import {
    Button,
    Stack,
    useColorModeValue
} from "@chakra-ui/react";

import BackdropModal from "components/modal/BackdropModal";

import { backendRoot, downloadOutputPath } from "backendInfo";

const DownloadModal = ({ isOpen, onOpen, onClose, taskId, model }) => {

    const buttonBgColor = useColorModeValue("white", "navy.800");
    const buttonBorderColor = useColorModeValue("gray.200", "whiteAlpha.300");
    const buttonTextColor = useColorModeValue("gray.800", "whiteAlpha.900");
    const buttonShadow = useColorModeValue("md", "lg");


    const handleDownload = (fileType) => {
        const downloadUrl = `${backendRoot}${downloadOutputPath}?task_id=${taskId}&type=${fileType}`;

        fetch(downloadUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const filenameHeader = response.headers.get('X-Filename');
                let filename = 'download'; // Default filename
                if (filenameHeader) {
                    try {
                        filename = JSON.parse(filenameHeader);
                    } catch (e) {
                        console.error('Error parsing filename:', e);
                    }
                }
                return response.blob().then(blob => ({ blob, filename }));
            })
            .then(({ blob, filename }) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Download error:', error);
                alert('Download failed. Please try again.');
            });
    };

    return (
        <BackdropModal isOpen={isOpen} onClose={onClose} title={"Download Options"} modalSize={"lg"} >

            <Stack spacing={4} direction="column" align="center" mb={6}>
                        <Button
                            variant="outline"
                            onClick={() => handleDownload("txt")}
                            width="100%"
                            bg={buttonBgColor}
                            borderColor={buttonBorderColor}
                            color={buttonTextColor}
                            _hover={{ bg: useColorModeValue("navy.100", "whiteAlpha.200") }}
                            boxShadow={buttonShadow}
                        >
                            Download TXT
                        </Button>
                        <Button
                            variant="outline"
                            onClick={() => handleDownload("srt")}
                            width="100%"
                            bg={buttonBgColor}
                            borderColor={buttonBorderColor}
                            color={buttonTextColor}
                            // isDisabled={model === 'chirp'}
                            _hover={{ bg: useColorModeValue("navy.100", "whiteAlpha.200") }}
                            boxShadow={buttonShadow}
                        >
                            Download SRT
                        </Button>
                        <Button
                            variant="outline"
                            onClick={() => handleDownload("txt_only_text")}
                            width="100%"
                            bg={buttonBgColor}
                            borderColor={buttonBorderColor}
                            color={buttonTextColor}
                            isDisabled={model === 'chirp'}
                            _hover={{ bg: useColorModeValue("navy.100", "whiteAlpha.200") }}
                            boxShadow={buttonShadow}
                        >
                            Download TXT (Text Only)
                        </Button>
            </Stack>

        </BackdropModal>
    );
};

export default DownloadModal;