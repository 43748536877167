
export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "title",
  },
  {
    Header:"SOURCE",
    accessor:"audio_source"
  },
  {
    Header:"MODEL",
    accessor:"model"
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "LANGUAGE",
    accessor: "language",
  },
  {
    Header: "NO. OF SPEAKERS",
    accessor: "speakers",
  },
  {
    Header: "CREATED AT",
    accessor: "created_at",
  },
  {
    Header: "DOWNLOAD",
    accessor: "download",
  }

];
