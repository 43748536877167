import {
  createContext,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";

import PropTypes from "prop-types";
import Cookies from "js-cookie";
import manager from "../helpers/manager";

const AppContext = createContext();

AppContext.displayName = "AppContext";

function AppProvider({ children }) {
  //temporay local consts
  // const navigate = useNavigate();
  //define states
  const [isAuthenticated, setIsAuthenticated] = useState(0);
  const [availableCredits, setAvailableCredits] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const emptyAllStatesAndLogout=()=>{
    setIsAuthenticated(0);
    Cookies.remove("token");
  }


  useEffect(() => {
    if (isAuthenticated) {
      manager.fetchUserInfo()
        .then((data) => {
          console.log('Fetched user data:', data.email);
          if (data && data.email) {
            setUserEmail(data.email);
          } else {
            console.error('Email not found in response:', data);
          }
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [isAuthenticated]); // Only depend on isAuthenticated

  useEffect(() => {
    if (userEmail) {
      console.log('Updated userEmail:', userEmail);
    }
  }, [userEmail]); 

  const states = {
    availableCredits,
    isAuthenticated,
    userEmail,
  };

  const actions = {
    setAvailableCredits,
    setIsAuthenticated,
    setUserEmail,
    emptyAllStatesAndLogout,
  };

  
  const value = { states, actions };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useApp() {
  const context = useContext(AppContext);
  return context;
}

export { AppProvider, useApp };
