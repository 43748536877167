import {
  Box,
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";

// Assets
import {
  MdCheckCircle,
  MdCancel,
  MdOutlineHourglassBottom,
  MdDownload,
  MdOutlineQueue,
  MdRefresh,
} from "react-icons/md";

import { languages } from "helpers/constants";
import DownloadModal from "views/tasks/list/components/download/downloadModal";
import { helpers } from "helpers/helperFunctions";

export default function ComplexTable(props) {
  const scrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: useColorModeValue('rgba(0,0,0,0.2)', 'rgba(255,255,255,0.2)'),
      borderRadius: '24px',
    },
  };
  const { columnsData, tableData, handleDownloads, handleReload } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [selectedTaskModel, setselectedTaskModel] = useState(null);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "CREATED AT",
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleDownload = (taskId, model) => {
    setSelectedTaskId(taskId);
    setselectedTaskModel(model);
    onOpen();
  };

  return (
    <Card direction="column" w="100%" px="0px" overflowX="auto">
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          All Tasks
        </Text>
        {/* <Menu /> */}
        <Icon
          as={MdRefresh}
          w="24px"
          h="24px"
          color={textColor}
          cursor="pointer"
          onClick={handleReload}
          _hover={{
            transform: "rotate(180deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Flex>
      <Box
        overflowX="auto"
        maxHeight="600px"
        css={scrollbarStyles}
      >
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead position="sticky">
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "NAME") {
                      data = (
                        <Text
                          whiteSpace="pre-wrap"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "SOURCE") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value.charAt(0).toUpperCase() +
                            cell.value.slice(1).toLowerCase()}
                        </Text>
                      );
                    } else if (cell.column.Header === "MODEL") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value.charAt(0).toUpperCase() +
                            cell.value.slice(1).toLowerCase()}
                        </Text>
                      );
                    } else if (cell.column.Header === "STATUS") {
                      data = (
                        <Flex align="center">
                          <Icon
                            w="24px"
                            h="24px"
                            me="5px"
                            color={
                              cell.value === "COMPLETED"
                                ? "green.500"
                                : cell.value === "FAILED"
                                ? "red.500"
                                : cell.value === "PROCESSING"
                                ? "blue.500"
                                : cell.value === "QUEUED"
                                ? "yellow.500"
                                : null
                            }
                            as={
                              cell.value === "COMPLETED"
                                ? MdCheckCircle
                                : cell.value === "FAILED"
                                ? MdCancel
                                : cell.value === "PROCESSING"
                                ? MdOutlineHourglassBottom
                                : cell.value === "QUEUED"
                                ? MdOutlineQueue
                                : null
                            }
                          />
                          <Text
                            color={
                              cell.value === "COMPLETED"
                                ? "green.500"
                                : cell.value === "FAILED"
                                ? "red.500"
                                : cell.value === "PROCESSING"
                                ? "blue.500"
                                : cell.value === "QUEUED"
                                ? "yellow.500"
                                : textColor
                            }
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "LANGUAGE") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {languages[cell.value]}
                        </Text>
                      );
                    } else if (cell.column.Header === "NO. OF SPEAKERS") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "CREATED AT") {
                      const dateOptions = {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      };
                      const timeOptions = {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      };
                      const date = new Date(cell.value).toLocaleDateString(
                        undefined,
                        dateOptions
                      );
                      const time = new Date(cell.value).toLocaleTimeString(
                        undefined,
                        timeOptions
                      );
                      const dateTime = `${date} ${time}`;
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {dateTime}
                        </Text>
                      );
                    } else if (cell.column.Header === "DOWNLOAD") {
                      if (row.original.status === "COMPLETED") {
                        data = (
                          <Flex
                            align="center"
                            onClick={() =>
                              handleDownload(
                                row.original.task_id,
                                row.original.model
                              )
                            }
                          >
                            <Icon
                              w="24px"
                              h="24px"
                              me="5px"
                              color="gray.400"
                              as={MdDownload}
                            />
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          </Flex>
                        );
                      } else {
                        data = null;
                      }
                      // data = (
                      //   <Flex align='center' onClick={() => handleDownload(row.original.task_id)}>
                      //     <Icon
                      //       w='24px'
                      //       h='24px'
                      //       me='5px'
                      //       color='gray.400'
                      //       as={MdDownload}
                      //     />
                      //     <Text color={textColor} fontSize='sm' fontWeight='700'>
                      //       {cell.value}
                      //     </Text>
                      //   </Flex>
                      // );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        maxWidth={
                          cell.column.Header === "NAME" ? "250px" : "auto"
                        }
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <DownloadModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        taskId={selectedTaskId}
        model={selectedTaskModel}
      />
    </Card>
  );
}
